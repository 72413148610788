import { Directive, Input, OnDestroy, TemplateRef, ViewContainerRef } from "@angular/core";
import { Observable, Subscription, of } from "rxjs";
import { LoadingComponent } from "./loading.component";

/**
 * Shows a loading spinner until the observable has emitted.
 */
@Directive({
    selector: "[appLoadingUntilFirstEmit]",
    standalone: false,
})
export class LoadingUntilFirstEmitDirective<T> implements OnDestroy {
    private subscription?: Subscription;
    private isLoading = false;

    public constructor(
        private templateRef: TemplateRef<{ $implicit: T }>,
        private viewContainer: ViewContainerRef,
    ) {}

    // eslint-disable-next-line @angular-eslint/no-input-rename
    @Input("appLoadingUntilFirstEmitOf")
    public set loadingObservable$(value$: T | Observable<T>) {
        // If a second observable is set before the first
        this.subscription?.unsubscribe();

        if (!this.isLoading) {
            this.isLoading = true;
            this.viewContainer.clear();
            this.viewContainer.createComponent(LoadingComponent);
        }

        if (!(value$ instanceof Observable)) {
            value$ = of(value$);
        }

        // Use a sentinel value so a first emit of undefined doesn't early exit below
        let previousValue: T | object = new Object();
        this.subscription = value$.subscribe((v) => {
            this.isLoading = false;

            if (previousValue === v) {
                return;
            }

            this.viewContainer.clear();
            this.viewContainer.createEmbeddedView(this.templateRef, {
                $implicit: v,
            });

            previousValue = v;
        });
    }

    public ngOnDestroy() {
        this.subscription?.unsubscribe();
    }

    static ngTemplateContextGuard<T>(
        _dir: LoadingUntilFirstEmitDirective<T>,
        ctx: unknown,
    ): ctx is { $implicit: T } {
        return true;
    }
}
